import WOW from 'wowjs';
import Slider from "react-slick";
import CountUp from 'react-countup';
import Flags from 'react-world-flags';
import Tab from 'react-bootstrap/Tab';
import styled from "styled-components";
import Modal from '@mui/material/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Typewriter from 'typewriter-effect';
import AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState, useRef } from 'react';

import 'animate.css';
import './index.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import 'react-h5-audio-player/lib/styles.css';
import { setScoreAPI, setCountryBoard, getCountryLeaderBoard, getPlayerLeaderBoard, setCountryInfo } from '../../store/actions/Auth';

/* All the constant values required for the game to work. By changing these values we can effect the working of the game */
const TRUMP_HEIGHT = 140, TRUMP_WIDTH = 150, WALL_HEIGHT = 918, WALL_WIDTH = 1920, GRAVITY = 8, OBJ_WIDTH = 80, OBJ_SPEED = 20, OBJ_GAP = 350, COIN_WIDTH = 60, COIN_SPEED = 20;

const tracks = [
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/donaldTheTank.mp3",
    title: "Donald The Tank",
    tags: ["donaldTheTank"],
  },
  // {
  //   url: "https://assets-softtik.s3.us-east-2.amazonaws.com/destroyNorthKorea.mp3",
  //   title: "Destroy North Korea",
  //   tags: ["destroyNorthKorea"],
  // },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/enemy-of-the-people.mp3",
    title: "Enemy of the people",
    tags: ["Enemyofthepeople"],
  },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/greatWall.mp3",
    title: "Great Wall",
    tags: ["greatWall"],
  },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/rocketman.mp3",
    title: "Rocket Man",
    tags: ["rocketman"],
  },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/youarefakenews.mp3",
    title: "You are Fake News",
    tags: ["rocketman"],
  },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/trump-won.mp3",
    title: "Trump Won - Natasha Owens",
    tags: ["trump-won"],
  },
  {
    url: "https://assets-softtik.s3.us-east-2.amazonaws.com/donald-trump-tribute.mp3",
    title: "Donald Trump Tribute God Bless the USA",
    tags: ["donald-trump-tribute"],
  },

];

const medalImages = [
  require('../../static/images/1.png'),
  require('../../static/images/2.png'),
  require('../../static/images/3.png'),
  require('../../static/images/4.png')
];

function FlippyTrump() {

  const player = useRef();
  const dispatch = useDispatch();
  const { publicKey } = useWallet();

  const { countryLeaderBoard, playerLeaderBoard } = useSelector(st => st['Auth']);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 9000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    rtl: true,
  };

  const [score, setScore] = useState(0);
  const [coinTop, setCoinTop] = useState(0);
  const [isShown, setShow] = useState(false);
  const [isCoin, setIsCoin] = useState(true);
  const [trackIdx, setTrackIdx] = useState(0);
  const [trumpTop, setTrumpTop] = useState(300);
  const [isStart, setIsStart] = useState(false);
  const [objHeight, setObjHeight] = useState(0);
  const [objPos, setObjPos] = useState(WALL_WIDTH);
  const [coinPos, setCoinPos] = useState(WALL_WIDTH);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isEnterModal, setIsEnterModal] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isLeaderBoard, setIsLeaderBoard] = useState(false);
  const [playerLeaderBoardData, setplayerLeaderBoardData] = useState([]);
  const [countryLeaderBoardData, setCountryLeaderBoardData] = useState([]);

  //Check Website Loaded!
  useEffect(() => {
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') setTimeout(() => setIsPageLoaded(true), 1500);
    }
  }, []);

  useEffect(() => {
    getIP();
    dispatch(getPlayerLeaderBoard());
    dispatch(getCountryLeaderBoard());
    new WOW.WOW({ live: true }).init();
  }, []);

  useEffect(() => {
    if (isLeaderBoard) setIsStart(false);
  }, [isLeaderBoard]);

  useEffect(() => {
    if (isGameOver && score > 0) {
      (async () => {
        try {
          const { countryName, countryCode } = await getIP();
          dispatch(setCountryBoard({ name: countryName, key: countryCode, score }));
          if (publicKey) dispatch(setScoreAPI({ score, publicAddress: publicKey.toBase58(), countryName, countryCode }));
        } catch (error) {
          console.error('Error fetching country information or setting score:', error);
          if (publicKey) dispatch(setScoreAPI({ score, publicAddress: publicKey.toBase58() }));
        }
      })();
    }
  }, [isGameOver]);

  const getIP = async () => {
    const response = await fetch('https://freeipapi.com/api/json');
    if (!response) throw new Error('Failed to fetch IP data');
    const data = await response.json();
    dispatch(setCountryInfo({ name: data['countryName'], code: data['countryCode'] }));
    return data;
  }

  useEffect(() => {
    if (Array.isArray(countryLeaderBoard) && countryLeaderBoard.length > 0) setCountryLeaderBoardData(countryLeaderBoard);
  }, [countryLeaderBoard]);

  useEffect(() => {
    if (Array.isArray(playerLeaderBoard) && playerLeaderBoard.length > 0) setplayerLeaderBoardData(playerLeaderBoard);
  }, [playerLeaderBoard]);

  //End the game when the player hits the bottom of the screen.
  useEffect(() => {
    let intVal;
    if (isStart && trumpTop < WALL_HEIGHT - TRUMP_HEIGHT) {
      intVal = setInterval(() => setTrumpTop((trumpTop) => trumpTop + GRAVITY), 20);
    } else {
      if (isStart) setIsGameOver(true);
      setIsStart(false);
      setTrumpTop(300);
      let random = Math.floor(Math.random() * 10);
      if (isStart && isShown) {
        if (random % 2 == 0) document.getElementById('you-are-fakenews').play();
        else document.getElementById('no-no-no').play();
      }
    }
    return () => clearInterval(intVal);
  });

  //Generating the pipes(obstacles) for the game.
  useEffect(() => {
    let objval;
    if (isStart && objPos >= -OBJ_WIDTH) {
      objval = setInterval(() => setObjPos((objPos) => objPos - OBJ_SPEED), 30);
      return () => clearInterval(objval)
    }
    else {
      setObjPos(WALL_WIDTH);
      setObjHeight(Math.floor(Math.random() * (WALL_HEIGHT - OBJ_GAP)));
      if (isStart && isShown) {
        // setScore((score) => score + 1);
        document.getElementById('pass').play();
      }
    }
  }, [isStart, objPos]);

  //Generating the coins for the game.
  useEffect(() => {
    let coinval;
    if (isStart && coinPos >= -COIN_WIDTH) {
      coinval = setInterval(() => setCoinPos((coinPos) => coinPos - COIN_SPEED), 40);
      return () => clearInterval(coinval)
    }
    else {
      setCoinPos(WALL_WIDTH);
      setCoinTop(Math.floor(Math.random() * (650 - 50 + 1) + 50));
    }
  }, [isStart, coinPos]);

  //Ends the game of the player hits one of the obstacles.
  useEffect(() => {
    let topObj = trumpTop >= 0 && trumpTop < objHeight - 10;
    let bottomObj = trumpTop <= WALL_HEIGHT && trumpTop >= WALL_HEIGHT - (WALL_HEIGHT - OBJ_GAP - objHeight) - TRUMP_HEIGHT;

    if (objPos >= OBJ_WIDTH && objPos <= OBJ_WIDTH + 250 && (topObj || bottomObj)) {
      let random = Math.floor(Math.random() * 10);
      setIsStart(false);
      setIsGameOver(true);
      setTrumpTop(900);
      if (isStart && isShown) {
        if (random % 2 == 0) document.getElementById('you-are-fakenews').play();
        else document.getElementById('no-no-no').play();
      }
    }
  }, [isStart, trumpTop, objHeight, objPos]);

  //Add Score when player take the coin
  useEffect(() => {
    if (isStart && isCoin) {
      if ((trumpTop <= (coinTop + 20) && trumpTop >= (coinTop - 80)) && coinPos < 320) {
        setIsCoin(false);
        setScore((score) => score + 1);
        document.getElementById('coin-collected').play();
        setTimeout(() => setIsCoin(true), 1000);
      }
    }
  }, [isStart, trumpTop, coinPos]);

  // Add isStart and trumpTop to the dependency list
  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => { window.removeEventListener('keypress', handleKeyPress) };
  }, [isStart, trumpTop]);


  //Handles the Space & Click Key Pressed
  const handleKeyPress = (e) => {
    if (e.code === 'Space') {
      // Prevent the default behavior to avoid scrolling the page
      e.preventDefault();
      setIsStart(true);
      setIsGameOver(false);
      if (!isStart) setScore(0);
      if (trumpTop > 0) setTrumpTop((prev) => prev - 100);
      if (isStart && isShown) document.getElementById('jump').play();
    }
  };

  // Check if the pressed key is the spacebar
  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
      // Prevent the default behavior to avoid scrolling the page
      event.preventDefault();
      if (isStart && isShown) document.getElementById('jump').play();
      // Trigger the click event
      handler();
    }
  };

  //Handles the player movements.
  const handler = () => {
    if (isShown) {
      if (!isStart && !isLeaderBoard) {
        setScore(0);
        setIsStart(true);
        setIsGameOver(false);
      }
      else if (trumpTop < TRUMP_HEIGHT) setTrumpTop(0);
      else if (trumpTop > 0) setTrumpTop((trumpTop) => trumpTop - 80);

      if (isShown) document.getElementById('jump').play();
    }
  };

  const handleEnterWebsite = () => {
    window.scrollTo(0, 0)
    setIsEnterModal(false);
    player?.current?.audio?.current.play();
  };

  const handleChangeAudio = () => setTrackIdx((trackIdx) => trackIdx < tracks.length - 1 ? trackIdx + 1 : 0);

  return (
    <div className='flappytrump-page'>
      <div>
        <Navbar isShown={isShown} />
        {isShown
          ? <section className='banner-area banner-game'>
            {/* //Whole body of the game. */}
            <Home onClick={handler} onKeyDown={handleKeyDown} tabIndex="0">
              <ScoreShow>{score}</ScoreShow>
              <Background height={WALL_HEIGHT} width={WALL_WIDTH}>
                {!isStart ? <>
                  <div className="background-start start-game">
                    <Startboard>
                      <div className='trump-animation'>
                      </div>
                    </Startboard>
                  </div>

                </> : null}
                <Obj height={objHeight} width={OBJ_WIDTH} left={objPos} top={0} deg={180} />
                {isCoin &&
                  <Coin height={60} width={60} left={coinPos} top={coinTop} deg={0} />
                }
                <Trump height={TRUMP_HEIGHT} width={TRUMP_WIDTH} top={trumpTop} left={250} deg={0} />
                <Obj height={WALL_HEIGHT - OBJ_GAP - objHeight} width={OBJ_WIDTH} left={objPos} top={WALL_HEIGHT - (objHeight + (WALL_HEIGHT - OBJ_GAP - objHeight))} deg={0} />
              </Background>
              {/* Game Over Score Board */}
              {isGameOver &&
                <GameOver height={WALL_HEIGHT} width={WALL_WIDTH}>
                  <div className="background-start">
                    <Startboard>
                      <div className='gameover-screen'>
                        <img src={require('../../static/images/gameover.png')} alt='' />
                        <div className='tump-image'>
                          <img src={require('../../static/images/trump-img.png')} alt='' />
                        </div>
                        <ScoreShow className='score-gameover'>{score}</ScoreShow>
                      </div>
                      <div className='icon-bottom'>
                        <button className='home-icon' onClick={() => setShow(false)}><img src={require('../../static/images/home-icon.png')} alt='' /></button>
                        <button onClick={() => setIsLeaderBoard(true)}><img src={require('../../static/images/trophy-icon.png')} alt='' /></button>
                        <button ><img src={require('../../static/images/restart-icon.png')} alt='' /></button>
                        <button><img src={require('../../static/images/share-icon.png')} alt='' /></button>
                      </div>
                    </Startboard>
                  </div>
                </GameOver>
              }
              <audio id="you-are-fakenews">
                <source src={require('../../static/sounds/youarefakenews.mp3')} type="audio/mp3" />
              </audio>
              <audio id="no-no-no">
                <source src={require('../../static/sounds/noNoNo.mp3')} type="audio/mp3" />
              </audio>
              <audio id="jump">
                <source src={require('../../static/sounds/bing.mp3')} type="audio/mp3" />
              </audio>
              <audio id="pass">
                <source src={require('../../static/sounds/kaching.mp3')} type="audio/mp3" />
              </audio>
              <audio id="start">
                <source src={require('../../static/sounds/start.mp3')} type="audio/mp3" />
              </audio>
              <audio id="coin-collected">
                <source src={require('../../static/sounds/coin-collected.mp3')} type="audio/mp3" />
              </audio>
              {isLeaderBoard
                ? <div className='leaderboard-screen'>
                  <img src={require('../../static/images/leader-board.png')} alt='' />
                  <div className='user-score-area'>
                    <Tabs>
                      <Tab eventKey="home" title="Country">
                        {countryLeaderBoardData.length > 0 &&
                          countryLeaderBoardData.map((country, index) => (
                            <div className='user-score-box' key={country?.['_id']}>
                              <div className='madile-img'>
                                <img src={medalImages[index] || medalImages[3]} alt='' />
                              </div>
                              <div className='user-area'>
                                <p>{index + 1}.</p>
                                <Flags code={country?.['key']} alt={country?.['name']} className='country-flag' />
                                <p className='contery-name'><span>{country?.['name'].length > 10 ? country?.['key'] : country?.['name']}</span></p>
                              </div>
                              <div className='score-area'>
                                <img src={require('../../static/images/score-bg.png')} alt='' />
                                <p>{country?.['score']}</p>
                              </div>
                            </div>
                          ))
                        }
                      </Tab>
                      <Tab eventKey="profile" title="Players">
                        {playerLeaderBoardData.length > 0 &&
                          playerLeaderBoardData.map((player, index) => {
                            const publicAddress = player?.['publicAddress'];
                            const shortenedAddress = `${publicAddress.slice(0, 5)}...${publicAddress.slice(-5)}`;
                            return (
                              <div className='user-score-box' key={player?.['_id']}>
                                <div className='madile-img'>
                                  <img src={medalImages[index] || medalImages[3]} alt='' />
                                </div>
                                <div className='user-area'>
                                  <p>{index + 1}.</p>
                                  <Flags code={player?.['countryCode']} alt={player?.['countryName']} className='country-flag' />
                                  <p className='contery-name'><span>{shortenedAddress}</span></p>
                                </div>
                                <div className='score-area'>
                                  <img src={require('../../static/images/score-bg.png')} alt='' />
                                  <p>{player?.['totalScore']}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Tab>
                    </Tabs>
                  </div>
                  <div className='icon-bottom'>
                    <button className='home-icon' onClick={() => setShow(!isShown)}><img src={require('../../static/images/home-icon.png')} alt='' /></button>
                    <button onClick={() => setIsLeaderBoard(false)}><img src={require('../../static/images/trophy-icon.png')} alt='' /></button>
                    <button><img src={require('../../static/images/restart-icon.png')} alt='' /></button>
                    <button><img src={require('../../static/images/share-icon.png')} alt='' /></button>
                  </div>
                </div>
                : <></>

              }
              <div className='social-icons'>
                <button className='home-icon' onClick={() => setShow(!isShown)}><img src={require('../../static/images/home-btn.png')} alt='' /></button>
                <div className='right-area'>
                  <a href="https://t.me/FlapTrump" target="_blank" className='social'><img src={require('../../static/images/telegram-icon.png')} alt='' /></a>
                  <a href="https://x.com/FlappyTrumpMeme" target="_blank" className='social'><img src={require('../../static/images/x.png')} alt='' /></a>
                </div>
              </div>
            </Home>
          </section>
          : <>
            {/*Banner Section */}
            <section className='banner-area banner-sec'>
              <div className="background-start">
                <div className='text-box wow slideInUp'>
                  <img src={require('../../static/images/text-banner.gif')} alt='' />
                  <h3 onClick={() => setShow(!isShown)}><i className='icon'><img src={require('../../static/images/play-icon.png')} alt='' /></i></h3>
                </div>
                <div className='left-play-area wow fadeIn'>
                  <div className='counter' onClick={() => setIsLeaderBoard(true)}>
                    <i className='icon'><img src={require('../../static/images/click-icon.png')} alt='' /></i>
                    <CountUp
                      start={0}
                      end={2000527.012} />
                  </div>
                  <div className='tap-area' onClick={() => setShow(!isShown)}>
                    <h3><i className='icon'><img src={require('../../static/images/play-icon.png')} alt='' /></i>
                      <Typewriter
                        options={{
                          strings: ['Tap to Flap', 'Tap to Flap'],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </h3>
                    <p>Tap to keep Trump  flapping and  avoid those ‘fake news’ obstacles and win meme coins!</p>
                  </div>
                </div>
                {isLeaderBoard &&
                  <div className='leaderboard-screen'>
                    <i className='cross-icon' onClick={() => setIsLeaderBoard(false)}><img className='charctor-img' src={require('../../static/images/cross-icon.png')} alt='' /></i>
                    <img src={require('../../static/images/leader-board.png')} alt='' />
                    <div className='user-score-area'>
                      <Tabs>
                        <Tab eventKey="home" title="Country">
                          {countryLeaderBoardData.length > 0 &&
                            countryLeaderBoardData.map((country, index) => (
                              <div className='user-score-box' key={country?.['_id']}>
                                <div className='madile-img'>
                                  <img src={medalImages[index] || medalImages[3]} alt='' />
                                </div>
                                <div className='user-area'>
                                  <p>{index + 1}.</p>
                                  <Flags code={country?.['key']} alt={country?.['name']} className='country-flag' />
                                  <p className='contery-name'><span>{country?.['name'].length > 10 ? country?.['key'] : country?.['name']}</span></p>
                                </div>
                                <div className='score-area'>
                                  <img src={require('../../static/images/score-bg.png')} alt='' />
                                  <p>{country?.['score']}</p>
                                </div>
                              </div>
                            ))
                          }
                        </Tab>
                        <Tab eventKey="profile" title="Players">
                          {
                            playerLeaderBoardData.length > 0 &&
                            playerLeaderBoardData.map((player, index) => {
                              const publicAddress = player?.['publicAddress'];
                              const shortenedAddress = `${publicAddress.slice(0, 5)}...${publicAddress.slice(-5)}`;
                              return (
                                <div className='user-score-box' key={player?.['_id']}>
                                  <div className='madile-img'>
                                    <img src={medalImages[index] || medalImages[3]} alt='' />
                                  </div>
                                  <div className='user-area'>
                                    <p>{index + 1}.</p>
                                    <Flags code={player?.['countryCode']} alt={player?.['countryName']} className='country-flag' />
                                    <p className='contery-name'><span>{shortenedAddress}</span></p>
                                  </div>
                                  <div className='score-area'>
                                    <img src={require('../../static/images/score-bg.png')} alt='' />
                                    <p>{player?.['totalScore']}</p>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                }
                <div className='cherctor-images'>
                  <img src={require('../../static/images/rocket.gif')} alt='' />
                  <img className='charctor-img' src={require('../../static/images/3.gif')} alt='' />
                </div>
                <div className='social-icons'>
                  <AudioPlayer
                    ref={player}
                    showSkipControls
                    autoPlay
                    showJumpControls={false}
                    autoPlayNextTrack={true}
                    src={tracks[trackIdx].url}
                    header={`${tracks[trackIdx].title}`}
                    onEnded={handleChangeAudio}
                    showFilledVolume={true}
                    onPlay={e => console.log("onPlay")}
                    onClickNext={handleChangeAudio}
                  />

                  <div className='right-area'>
                    <a href="https://t.me/FlapTrump" target="_blank" className='social'><img src={require('../../static/images/telegram-icon.png')} alt='' /></a>
                    <a href="https://x.com/FlappyTrumpMeme" target="_blank" className='social'><img src={require('../../static/images/x.png')} alt='' /></a>
                  </div>
                </div>
              </div>
            </section>
            {/* Features Section */}
            <section className='features-sec' id='leaderboard-sec'>
              <div className='cloud-1'><img src={require('../../static/images/cloud-1.png')} alt='' /></div>
              <div className='cloud-2'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='cloud-3'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='auto-container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='sec-title wow slideInUp'>
                      <h2><img src={require('../../static/images/features.gif')} alt='' /></h2>
                    </div>
                    <div className='features-area'>
                      <div className='features-box wow fadeInLeft'>
                        <h3>Don't Trust Fake News</h3>
                        <p>Dodge those pesky Fake News obstacles that pop up faster than Trump's tweets! </p>
                        <div className='img-box'>
                          <img src={require('../../static/images/fake-news.png')} alt='' />
                        </div>
                      </div>

                      <div className='features-box-2 wow fadeInUp'>
                        <h3>LeaderBoard</h3>
                        <div className='midale-area'>
                          <img src={require('../../static/images/gold.png')} alt='' />
                          <img src={require('../../static/images/silver.png')} alt='' />
                          <img src={require('../../static/images/bronze.png')} alt='' />
                        </div>
                        {
                          countryLeaderBoardData.length > 0 &&
                          <>
                            <div className='user-score-area'>
                              <div className='score-top'>
                                <p>Score</p>
                              </div>
                              {countryLeaderBoardData.map((country, index) => (
                                <div className='user-score-box' key={country?.['_id']}>
                                  <div className='madile-img'>
                                    <img src={medalImages[index] || medalImages[3]} alt='' />
                                  </div>
                                  <div className='user-area'>
                                    <p>{index + 1}.</p>
                                    <Flags code={country?.['key']} alt={country?.['name']} className='country-flag' />
                                    <p className='contery-name'><span>{country?.['name'].length > 10 ? country?.['key'] : country?.['name']}</span></p>
                                  </div>
                                  <div className='score-area'>
                                    <img src={require('../../static/images/score-bg.png')} alt='' />
                                    <p>{country?.['score']}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <button className='more-btn'>
                              <img src={require('../../static/images/arrow-bottom.png')} alt='' />
                            </button>
                          </>
                        }
                      </div>
                      <div className='features-box features-box-3 wow fadeInRight'>
                        <h3>Earn Meme Coins:</h3>
                        <p>Rack up scores for meme coins to unlock Trump-tastic rewards. </p>
                        <div className='img-box'>
                          <img src={require('../../static/images/gift-box.png')} alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='bttom-text'>
                      <p>Join the fun and flap your way to victory in Flappy Trump. It's politics meets<br />memes in the most hilarious way possible! </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-container marque-area">
                <Slider {...settings} className='wow fadeInLeft'>
                  <div> <p>$FLAPTRUMP</p> </div>
                  <div> <p>$FLAPTRUMP</p> </div>
                  <div> <p>$FLAPTRUMP</p> </div>
                  <div> <p>$FLAPTRUMP</p> </div>
                  <div> <p>$FLAPTRUMP</p> </div>
                  <div> <p>$FLAPTRUMP</p> </div>
                </Slider>
              </div>
            </section>

            {/* Flappynomic Section */}
            <section className='flappynomic-sec' id='tokenomics-sec' >
              <div className='kite-2'><img src={require('../../static/images/kite-2.png')} alt='' /></div>
              <div className='plance-2'><img src={require('../../static/images/plane-2.png')} alt='' /></div>
              <div className='plance-3'><img src={require('../../static/images/plane-3.png')} alt='' /></div>
              <div className='auto-container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='sec-title wow slideInUp'>
                      <h2><img src={require('../../static/images/flapnomic.gif')} alt='' /></h2>
                    </div>
                    <div className='cloude-token'>
                      <div className='cloude-token-box wow fadeInLeft'>
                        <img src={require('../../static/images/token-1.png')} alt='' />
                      </div>
                      <div className='cloude-token-box wow fadeInUp'>
                        <img src={require('../../static/images/token-2.png')} alt='' />
                      </div>
                      <div className='cloude-token-box wow fadeInRight'>
                        <img src={require('../../static/images/token-3.png')} alt='' />
                      </div>
                    </div>
                    <div className='cloude-token style-two'>
                      <div className='cloude-token-box wow fadeInLeft'>
                        <img src={require('../../static/images/token-4.png')} alt='' />
                      </div>
                      <div className='cloude-token-box wow fadeInRight'>
                        <img src={require('../../static/images/token-5.png')} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='cloud-1'><img src={require('../../static/images/cloud-3.png')} alt='' /></div>
              <div className='plance-1'><img src={require('../../static/images/plane.png')} alt='' /></div>
              <div className='kite-1'><img src={require('../../static/images/kite-1.png')} alt='' /></div>
            </section>

            {/* tokenomics Section */}
            {/* <section className='flappynomic-sec' id='tokenomics-sec' >
              <div className='kite-2'><img src={require('../../static/images/kite-2.png')} alt='' /></div>
              <div className='plance-2'><img src={require('../../static/images/plane-2.png')} alt='' /></div>
              <div className='plance-3'><img src={require('../../static/images/plane-3.png')} alt='' /></div>
              <div className='auto-container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='sec-title wow slideInUp'>
                      <h2><img src={require('../../static/images/flapnomic.gif')} alt='' /></h2>
                    </div>
                    <div className='tokenomics-box-center'>
                      <div className='tokenomics-center-img'>
                        <img src={require('../../static/images/tokenomic-center-img.png')} alt='' />
                        <div className='overlyabox'>
                          <img className='charctor-img' src={require('../../static/images/3.gif')} alt='' />
                        </div>
                        <div className='arrows-img'>
                          <img src={require('../../static/images/tokenomic-arrows.png')} alt='' />
                        </div>
                      </div>
                      <div className='tokenomics-box tokenomics-box-1'>
                        <div className='img-box'>
                          <img src={require('../../static/images/tokenomic-1.png')} alt='' />
                        </div>
                      </div>

                      <div className='tokenomics-box tokenomics-box-2'>
                        <div className='img-box'>
                          <img src={require('../../static/images/tokenomic-2.png')} alt='' />
                        </div>
                      </div>

                      <div className='tokenomics-box tokenomics-box-3'>
                        <div className='img-box'>
                          <img src={require('../../static/images/tokenomic-3.png')} alt='' />
                        </div>
                      </div>

                      <div className='tokenomics-box tokenomics-box-4'>
                        <div className='img-box'>
                          <img src={require('../../static/images/tokenomic-4.png')} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='cloud-1'><img src={require('../../static/images/cloud-3.png')} alt='' /></div>
              <div className='plance-1'><img src={require('../../static/images/plane.png')} alt='' /></div>
              <div className='kite-1'><img src={require('../../static/images/kite-1.png')} alt='' /></div>
            </section> */}

            {/* Buy Section */}
            <section className='buy-sec' id='buy-sec'>
              <div className='cloud-1'><img src={require('../../static/images/buy-cloud.png')} alt='' /></div>
              <div className='cloud-2'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='cloud-3'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='auto-container'>
                <div className='row'>
                  <div className='col-lg-5 col-md-12'>
                    <div className='sec-title wow slideInUp'>
                      <h2>
                        <Typewriter
                          options={{
                            strings: ['BUY NOW !!! ', 'BUY NOW !!! '],
                            autoStart: true,
                            loop: true,
                          }}
                        />
                      </h2>
                    </div>
                    <div className='img-buy'>
                      <img src={require('../../static/images/buy-img.gif')} alt='' />
                      <img className='bottom-shadow' src={require('../../static/images/bottom-shadow.png')} alt='' />
                    </div>
                  </div>
                  <div className='col-lg-6 offset-lg-1 col-md-12'>
                    <div className='right-area'>
                      <div className='form-box'>
                        <iframe src="https://raydium.io/swap/?inputMint=sol&outputMint=E4X6PrQVB96fj76omGXN2SAh1xsNF3FA1P2aeNpk7Srf" height="200" width="300" title="Iframe Example"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-container marque-area">
                <Slider {...settings} className='wow fadeInLeft'>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                </Slider>
              </div>
            </section>
            {/* Flappymap Section */}
            <section className='flappymap-sec' id='roadmap-sec'>
              <div className='cloud-2'><img src={require('../../static/images/cloud-roadmap.png')} alt='' /></div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='sec-title  wow slideInUp'>
                      <h2><img src={require('../../static/images/flapymap.gif')} alt='' /></h2>
                    </div>
                    <div className='roadmaap-area'>
                      <div className='roadmap-bg'>
                        <div className='rocket'><img src={require('../../static/images/trump.gif')} alt='' /></div>
                        <div className='roadmap-block roadmap-block-1'>
                          <img src={require('../../static/images/phase-1.png')} alt='' />
                        </div>
                        <div className='roadmap-block roadmap-block-2'>
                          <img src={require('../../static/images/phase-2.png')} alt='' />
                        </div>
                        <div className='roadmap-block roadmap-block-3'>
                          <img src={require('../../static/images/phase-3.png')} alt='' />
                        </div>
                        <div className='roadmap-block roadmap-block-4'>
                          <img src={require('../../static/images/phase-4.png')} alt='' />
                        </div>
                        <img src={require('../../static/images/roadmap-bg.png')} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-container marque-area">
                <Slider {...settings}>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                  <div>
                    <p>$FLAPTRUMP</p>
                  </div>
                </Slider>
              </div>
            </section>
            {/* Join Section */}
            <section className='join-sec'>
              <div className='cloud-2'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='cloud-3'><img src={require('../../static/images/cloud-2.png')} alt='' /></div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-4 offset-lg-1'>
                    <div className='sec-title'>
                      <h2><img src={require('../../static/images/join-text.gif')} alt='' /></h2>
                      <ul>
                        <li>
                          <a href="https://t.me/FlapTrump" target="_blank"><img src={require('../../static/images/telelgram-footer.png')} alt='' /></a>
                        </li>
                        <li>
                          <a href="https://x.com/FlappyTrumpMeme" target="_blank"><img src={require('../../static/images/x-footer.png')} alt='' /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-6 offset-lg-1'>
                    <div className='join-img'>
                      <h2><img src={require('../../static/images/join-me-img.gif')} alt='' /></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className='footer-img'>
                <img src={require('../../static/images/footer-bg.png')} alt='' />
              </div>
            </section>
          </>
        }
      </div>

      {/* Enter Website */}
      <Modal open={isEnterModal} onClose={() => setIsEnterModal(false)} className='modal-enter-website'>
        <div className='body-modal'>
          <div className='modal-inner'>
            <h1>Wanna Make</h1>
            <div className='flapy-text'>
              <img src={require('../../static/images/text-banner.gif')} alt='' />
            </div>
            <h1>Flap?</h1>
            {isPageLoaded
              ? <button className='entry-btn' onClick={() => handleEnterWebsite()}>Start</button>
              : <i class="loader"><img src={require('../../static/images/spinner.gif')} alt='' /></i>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FlippyTrump;


//All the stylesheets required for the game.
const Home = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flexDirection: 'column';
`;
const Background = styled.div`
  background-image: url("./images/background-day.jpg");
  background-repeat: no-repeat;
    background-size: 100% 100%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  overflow: hidden;
`;
const GameOver = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(0, -30%);
`;
const Trump = styled.div`
  position: absolute;
  background-image: url("./images/trump.gif");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: rotate(${(props) => props.deg}deg);
`;
const Coin = styled.div`
  position: absolute;
  background-image: url("./images/coin.gif");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: rotate(${(props) => props.deg}deg);
`;
const Obj = styled.div`
  position: relative;
  background-image: url("./images/pipe-green.png");
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  background-repeat: x-repeat;
  transform: rotate(${(props) => props.deg}deg);
`;
const Startboard = styled.div`
  position: absolute;
  top: auto;
  width: 351px;
  left: 50%;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 35px;
  cursor: pointer;
`;
const ScoreShow = styled.div`
  position: absolute;
  top: 39px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  background-image: url("./images/score-bg.png");
  background-repeat: no-repeat;
  width: 160px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #692f09;
  font-family: 'Impact Regular';
  font-size: 28px;
`;