import Flags from 'react-world-flags';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { signIn, getUser } from '../../store/actions/Auth';

import './index.css';
import('@solana/wallet-adapter-react-ui/styles.css');

const Navbar = ({ isShown }) => {
    const dispatch = useDispatch();
    const { publicKey, connected } = useWallet();
    const { auth, user, countryInfo } = useSelector(st => st['Auth']);

    useEffect(() => setWallet(), []);
    useEffect(() => setWallet(), [publicKey]);
    const setWallet = () => {
        if (publicKey) {
            let publicAddress = publicKey.toBase58();
            if (!auth) dispatch(signIn({ publicAddress }));
            else dispatch(getUser(publicAddress));
        }
    }

    return (
        <div className="flappytrump-nav">
            <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                <div className='container-fluid'>
                    <div className="inner-container">
                        <Link className='navbar-brand' to='/'><img src={require('../../static/images/logo.png')} alt='' /></Link>
                        <div className=' navbar-collapse nav-links' id='navbarSupportedContent'>
                            {!isShown &&
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#roadmap-sec'>RoadMap</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#tokenomics-sec'>Tokenomics</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#leaderboard-sec'>Leaderboard</HashLink>
                                    </li>
                                </ul>
                            }
                            <div className='wallet-btn'>
                                <div className='wallet-inner'>
                                    {countryInfo?.['name'] &&
                                        <i className='icon-flag'>
                                            <Flags code={countryInfo?.['code']} alt={countryInfo?.['name']} className='country-flag' />
                                        </i>
                                    }
                                    <WalletMultiButton className='wallet-btns' />
                                    {connected &&
                                        <div className='score-area'>
                                            <i className='icon'></i>
                                            <p>{user?.['totalScore']}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* <div className="whatsapp-swipe">
                <ul>
                    <li><a href="#" className="whatsapp-icon" rel="nofollow noopener"></a></li>
                    <li><a href="#" className="skype-icon" rel="nofollow noopener"></a></li>
                </ul>
            </div> */}
        </div>
    );
}
export default Navbar;