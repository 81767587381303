/* -- set app title --*/
const AppTitle = 'Frontend FlappyTrump NFT';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['production'];

/* -- set API URLs --*/
const production = 'https://server.flaptrump.co';
const development = 'https://server.flaptrump.co';

let env = AppMode[0] || 'development', baseURL;
switch (AppMode[0]) {
  case 'development':
    baseURL = development;
    break;
  case 'production':
    baseURL = production;
    break;
  default:
    baseURL = 'http://localhost:4000';
    break;
};

let ApiUrl = `${baseURL}/api/`;
export { AppTitle, ApiUrl, baseURL, env };